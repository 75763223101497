<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Report'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-row>
                  <b-col>
                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="date_label"
                      label="Date :"
                      label-for="date"
                    >
                      <b-form-input
                        id="date"
                        v-model="form.date"
                        type="date"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="report_no_label"
                      label="Nomor Laporan :"
                      label-for="report_no"
                      description="Nomor Laporan Diisi Manual"
                    >
                      <b-form-input
                        id="report_no"
                        type="text"
                        v-model="form.report_no"
                        placeholder="Enter Text..."
                        size="sm"
                        ref="report_no"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="date_label"
                      label="No Notifikasi :"
                      label-for="no_notifikasi"
                    >
                      <b-form-input
                        id="no_notifikasi"
                        v-model="form.no_notifikasi"
                        type="text"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="no_wo_label"
                      label="No. WO :"
                      label-for="no_wo"
                    >
                      <b-form-input
                        id="no_wo"
                        type="text"
                        v-model="form.no_wo"
                        placeholder="Enter Text..."
                        size="sm"
                        ref="no_wo"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="issued_to_label"
                      label="Tujuan Laporan :"
                      label-for="issued_to"
                    >
                      <treeselect
                        size="sm"
                        id="issued_to"
                        v-model="form.issued_to"
                        :multiple="true"
                        :clearable="true"
                        :searchable="true"
                        :disabled="false"
                        :openOnClick="true"
                        :openOnFocus="false"
                        :clearOnSelect="true"
                        :closeOnSelect="false"
                        :alwaysOpen="false"
                        :appendToBody="false"
                        :rtl="false"
                        :async="true"
                        valueFormat="label"
                        :load-options="loadOptionsIssued"
                        @select="onSelect"
                      />
                    </b-form-group>

                    <!-- <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="eksekutor_label"
                      label="Eksekutor :"
                      label-for="eksekutor"
                    >
                      <b-form-select
                        size="sm"
                        id="eksekutor"
                        v-model="form.eksekutor"
                        :options="eksekutorOptions"
                      ></b-form-select>
                    </b-form-group> -->
                  </b-col>

                  <b-col>
                    <h5><u>Inspection Detail</u></h5>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="co_inspector_label"
                      label="Co Inspector :"
                      label-for="co_inspector"
                    >
                      <treeselect
                        size="sm"
                        id="co_inspector"
                        v-model="form.co_inspector"
                        :clearable="true"
                        :multiple="true"
                        :async="true"
                        valueFormat="label"
                        :load-options="loadOptionsIssued"
                        @select="onSelect"
                      />
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="file_label"
                      label="File :"
                      label-for="file"
                    >
                      <b-form-file
                        id="file"
                        accept=".docx, .doc, .ppt, .pptx, .pdf"
                        v-model="form.file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        size="sm"
                      ></b-form-file>
                    </b-form-group>

                    <h5><u>Lokasi</u></h5>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="pabrik_id_label"
                      label="Pabrik :"
                      label-for="pabrik_id"
                    >
                      <b-form-select
                        size="sm"
                        id="pabrik"
                        v-model="form.pabrik_id"
                        :options="pabrik"
                        @change="choosePabrik"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="plant_id_label"
                      label="Plant :"
                      label-for="plant_id"
                    >
                      <b-form-select
                        id="plant_id"
                        v-model="form.plant_id"
                        :options="plant"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="equipment_id_label"
                      label="Equipment :"
                      label-for="equipment_id"
                    >
                      <vue-suggestion
                        id="equipment_id"
                        :items="equipment"
                        v-model="ItemEquipment"
                        :setLabel="setLabel"
                        :itemTemplate="itemTemplate"
                        @changed="inputChangeEquipment"
                        @selected="chooseEquipment"
                        inputClasses="form-control"
                        placeholder="Enter Text..."
                        size="sm"
                      >
                      </vue-suggestion>
                    </b-form-group>

                   
                    <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="onstream_label_radio"
                    label="Waktu Inspeksi :"
                    label-for="onstream_radio"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-radio-group
                      id="onstream_radio"
                      size="sm"
                      v-model="modalForm.onstream"
                      :options="onstream"
                      :aria-describedby="ariaDescribedby"
                      name="radio-onstream_radio"
                      stacked
                    ></b-form-radio-group>
                  </b-form-group>
                       
                    
                  </b-col>

                </b-row>

                <hr />
                <b-button 
                    v-if="modalForm.onstream === 'Onstream'" 
                    v-b-modal.modal-add-item-onstream 
                    class="mb-2" 
                    variant="outline-info" 
                    size="sm"
                  >
                    <span class="svg-icon svg-icon-sm svg-icon-dark">
                      <i class="flaticon2-plus"></i>
                    </span>
                    Add Onstream Item
                  </b-button>

                  <!-- Button yang muncul jika shutdown dipilih -->
                  <b-button 
                    v-if="modalForm.onstream === 'Shutdown'" 
                    v-b-modal.modal-add-item 
                    class="mb-2" 
                    variant="outline-danger" 
                    size="sm"
                  >
                    <span class="svg-icon svg-icon-sm svg-icon-dark">
                      <i class="flaticon2-plus"></i>
                    </span>
                    Add Shutdown Item
                  </b-button>    
                <!-- <b-button v-b-modal.modal-add-item class="mb-2" variant="outline-info" size="sm">
                  <span class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-plus"></i>
                  </span>
                  Add Item
                </b-button> -->

                

                <h4>Detail Item</h4>
                <b-row>
                  <b-col md="12">
                    <b-table
                      head-variant="dark"
                      bordered
                      :items="items"
                      :fields="fields"
                      :current-page="currentPage"
                      stacked="sm"
                      show-empty
                      responsive
                      @filtered="onFiltered"
                      :busy="isBusy"
                    >
                      <template #cell(no)="data">
                        {{ (currentPage - 1) * 10 + data.index + 1 }}
                      </template>

                      <template #cell(image)="data">
                        {{ data.item.img.name }}
                      </template>

                      <template #cell(actions)="data">
                        <div
                          class="
                                btn btn-icon btn-clean btn-lg btn-dropdown
                                mr-1
                              "
                          @click="data.toggleDetails"
                        >
                          <span class="svg-icon svg-icon-sm svg-icon-success">
                            <i class="flaticon-eye text-success"></i>
                          </span>
                        </div>
                        <div
                          class="
                                btn btn-icon btn-clean btn-lg btn-dropdown
                                mr-1
                              "
                        >
                          <span
                            class="svg-icon svg-icon-lg svg-icon-info"
                            @click="toEdit(data.index)"
                          >
                            <i class="flaticon2-pen text-primary"></i>
                          </span>
                        </div>
                        <div
                          class="
                                btn btn-icon btn-clean btn-lg btn-dropdown
                                mr-1
                              "
                        >
                          <span
                            class="svg-icon svg-icon-lg svg-icon-danger"
                            @click="toDelete(data.index)"
                          >
                            <i class="flaticon2-trash text-danger"></i>
                          </span>
                        </div>
                      </template>

                      <template #row-details="data">
                        <b-card>
                          <b-row
                            class="mb-2"
                            v-for="(img, index) in data.item.img"
                            v-bind:key="index"
                          >
                            <b-col sm="3" class="text-sm-right"
                              ><b>Gambar {{ index + 1 }} :</b></b-col
                            >
                            <b-col>
                              {{ img.name }}
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Critically :</b></b-col>
                            <b-col>
                              <span v-html="data.item.critically"></span>
                            </b-col>
                          </b-row>
                          <!-- <b-form-group label="Tautan History Card:">
                      
                          <a :href="urlInput" target="_blank">Buka Tautan</a>
                    
                          </b-form-group> -->
                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Waktu Perbaikan :</b></b-col>
                            <b-col>
                              <span v-html="data.item.onstream"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Status :</b></b-col>
                            <b-col>
                              <span v-html="data.item.status"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Hasil Pemeriksaan :</b></b-col>
                            <b-col>
                              <span v-html="data.item.inspection_result"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Rekomendasi :</b></b-col>
                            <b-col>
                              <span v-html="data.item.recomendation"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"
                              ><b>Perbaikan yang dilakukan :</b></b-col
                            >
                            <b-col>
                              <span v-html="data.item.repair_taken"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"
                              ><b>Rekomendasi masa mendatang :</b></b-col
                            >
                            <b-col>
                              <span v-html="data.item.future_recomendation"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Metode :</b></b-col>
                            <b-col>
                              <ul>
                                <li v-for="(row, index) in data.item.method" :key="index">
                                  {{ row }}
                                </li>
                              </ul>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Mekanisme Kerusakan :</b></b-col>
                            <b-col>
                              <ul>
                                <li v-for="(row, index) in data.item.damage_mechanism" :key="index">
                                  {{ row.label }}
                                </li>
                              </ul>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Prioritas :</b></b-col>
                            <b-col>
                              <span v-html="data.item.priority"></span>
                            </b-col>
                          </b-row>
                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Link Attachment :</b></b-col>
                            <b-col>
                              <a :href="data.item.attachment_link" target="_blank">Buka Tautan</a>
                            </b-col>
                            
                          </b-row>
                          <!-- <b-row class="mb-2">
                            <template v-if="isLinkAvailable">
                              <a :href="url_template" target="_blank">{{ linkText }}</a>
                            </template>
                            <template v-else>
                              <span>{{ linkText }}</span>
                            </template>
                          </b-row> -->

                          <span
                            class="svg-icon svg-icon-sm svg-icon-dark"
                            @click="data.toggleDetails"
                          >
                            <i class="flaticon-close text-dark"></i>
                          </span>
                        </b-card>
                      </template>

                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                  <b-col md="5">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="10"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                  <b-col md="5">
                    <h4 class="float-right">
                      Showing
                      <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                      <span v-else>0</span>

                      of {{ totalRows }}
                    </h4>
                  </b-col>
                </b-row>

                <hr />

                <b-modal ref="error-modal" title="Peringatan!">
                  <b-alert
                    class="pl-2"
                    variant="danger"
                    :fade="true"
                    v-model="showDismissibleAlert"
                  >
                    <br />
                    <span v-for="(val, index) in errors" :key="index">
                      <ul>
                        <li v-for="(arr, i) in val" :key="i">
                          {{ arr }}
                        </li>
                      </ul>
                      <br />
                    </span>
                  </b-alert>
                </b-modal>

                <b-button variant="outline-dark" :disabled="loading" @click="$router.go(-1)">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
          <b-modal
            id="modal-add-item"
            title="Add Shutdown Item"
            ref="modal"
            size="xl"
            
            @show="resetModal"
            @ok="handleOk"
            :hideHeaderClose="true"
            :no-close-on-backdrop="true"
          >
            <template #modal-title>
              {{ formTitle }}
            </template>
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-row>
                <b-col>
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Date :"
                  >
                    <b-form-input v-model="form.date" type="date" size="sm" disabled></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Nomor Laporan :"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Enter Text..."
                      size="sm"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Tujuan Laporan :"
                  >
                    <treeselect
                      size="sm"
                      v-model="form.issued_to"
                      :clearable="true"
                      :multiple="true"
                      :clearOnSelect="true"
                      :async="true"
                      valueFormat="label"
                      :load-options="loadOptionsIssued"
                      disabled
                    />
                  </b-form-group>

                  <h5><u>Lokasi</u></h5>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Pabrik :"
                    label-for="pabrik_id"
                  >
                    <b-form-input
                      v-model="form.pabrik"
                      type="text"
                      disabled
                      size="sm"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Plant :"
                    label-for="plant_id"
                  >
                    <b-form-select
                      id="plant_id"
                      v-model="form.plant_id"
                      :options="plant"
                      disabled
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Equipment :"
                  >
                    <vue-suggestion
                      :items="equipment"
                      v-model="ItemEquipment"
                      :setLabel="setLabel"
                      :itemTemplate="itemTemplate"
                      inputClasses="form-control"
                      size="sm"
                      disabled
                    >
                    </vue-suggestion>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="part_label"
                    label="Part :"
                    label-for="part_id"
                  >
                    <vue-suggestion
                      id="part_id"
                      :items="part"
                      v-model="ItemPart"
                      :setLabel="setLabel"
                      :itemTemplate="itemTemplate"
                      @changed="inputChangePart"
                      @selected="itemSelectedPart"
                      inputClasses="form-control"
                      placeholder="Enter Text..."
                    >
                    </vue-suggestion>
                  </b-form-group>

                  <div v-if="inspection_plant">
                    <b-form-group
                      label="Cek Inspection Plan pada tombol di bawah, Apakah item ini untuk Inspektion Plan tersebut?"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="modalForm.inspection_plan"
                        :options="[
                          { text: 'Ya', value: 'Ya' },
                          { text: 'Tidak', value: 'Tidak' },
                        ]"
                        :aria-describedby="ariaDescribedby"
                        name="inspection_plan"
                      ></b-form-radio-group>
                    </b-form-group>

                    <div class="accordion" role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle.accordion-1 variant="info">
                            <span class="svg-icon svg-icon-sm svg-icon-white">
                              <i class="flaticon-eye text-success"></i>
                            </span>
                            Detail Inspection Plan
                          </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                          <b-card-body style="font-size: 0.8rem">
                            <b-row class="mb-2">
                              <b-col sm="6" class="text-sm-right"
                                ><b>Mekanisme Kerusakan :</b></b-col
                              >
                              <b-col>{{ inspection_plant.damage_mechanism }}</b-col>
                            </b-row>

                            <b-row class="mb-2">
                              <b-col sm="6" class="text-sm-right"><b>Metode Inspeksi :</b></b-col>
                              <b-col>{{ inspection_plant.metode_inspeksi }}</b-col>
                            </b-row>

                            <b-row class="mb-2">
                              <b-col sm="6" class="text-sm-right"><b>Rencana Inspeksi :</b></b-col>
                              <b-col>{{ moment(inspection_plant.rencana_inspeksi) }}</b-col>
                            </b-row>

                            <b-row class="mb-2" v-if="inspection_plant.alasan_ditunda">
                              <b-col sm="6" class="text-sm-right"><b>Alasan Ditunda :</b></b-col>
                              <b-col>{{ inspection_plant.alasan_ditunda }}</b-col>
                            </b-row>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>

                  <b-alert v-else show variant="warning">Belum ada Inspection Plant.</b-alert>
                  <br />

                  <h5><u>Inspection Detail</u></h5>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="method_label"
                    label="Metode :"
                    label-for="method"
                  >
                    <treeselect
                      size="sm"
                      id="method"
                      v-model="modalForm.method"
                      :options="method"
                      :clearable="true"
                      :multiple="true"
                      placeholder="Pilih Metode Inspeksi..."
                    />
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="damage_mechanism_label"
                    label="Mekanisme Kerusakan :"
                    label-for="damage_mechanism"
                  >
                    <treeselect
                      size="sm"
                      id="damage_mechanism"
                      v-model="modalForm.damage_mechanism"
                      :clearable="true"
                      :multiple="true"
                      :async="true"
                      valueFormat="label"
                      :load-options="loadOptionsDamageMechanism"
                      @select="onSelect"
                    />
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Co Inspector :"
                  >
                    <treeselect
                      size="sm"
                      v-model="form.co_inspector"
                      :clearable="true"
                      :multiple="true"
                      :async="true"
                      valueFormat="label"
                      :load-options="loadOptionsIssued"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="inspection_result_label"
                    label="Hasil Pemeriksaan :"
                    label-for="inspection_result"
                  >
                    <vue-editor
                      id="inspection_result"
                      :editorToolbar="customToolbar"
                      v-model="modalForm.inspection_result"
                    ></vue-editor>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="critically_label"
                    label="Critically :"
                    label-for="critically"
                  >
                    <b-form-select
                      size="sm"
                      id="critically"
                      v-model="modalForm.critically"
                      :options="critically"
                    ></b-form-select>
                  </b-form-group>
                  
                  
                 
                  <b-form-group label="Link Template :" label-for="link_template">
                    <template v-if="isLinkAvailable">
                      <a :href="url_template" target="_blank">{{ linkText }}</a>
                    </template>
                    <template v-else>
                      <span>{{ linkText }}</span>
                    </template>
                  </b-form-group>

                  <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="link_baru"
                      label="Link Attachment Baru :"
                      label-for="link_baru"
                    >
                      <b-form-input
                        id="link_baru"
                        v-model="modalForm.attachment_link"
                        type="text"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="img_label"
                    label="Image :"
                    label-for="img"
                    v-if="modalForm.image.length < 6"
                  >
                    <b-button class="mb-2" variant="outline-info" size="sm" @click="addImage">
                      <span class="svg-icon svg-icon-sm svg-icon-dark">
                        <i class="flaticon2-plus"></i>
                      </span>
                      Tambah Gambar
                    </b-button>
                  </b-form-group>

                  <div v-for="(img, index) in modalForm.image" v-bind:key="index">
                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      :id="`img_label_${index}`"
                      :label="`Gambar ${index + 1} :`"
                      :label-for="`img_${index}`"
                    >
                      <b-form-file
                        :id="`img_${index}`"
                        accept=".jpg, .png, .jpeg, .pdf"
                        v-model="modalForm.image[index]"
                        placeholder="Choose a photo or drop it here..."
                        drop-placeholder="Drop image here..."
                      ></b-form-file>
                      <b-form-input
                        class="mt-1"
                        v-model="modalForm.image_keterangan[index]"
                        :placeholder="`Keterangan Gambar ${index + 1}...`"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="recomendation_label"
                    label="Rekomendasi :"
                    label-for="recomendation"
                  >
                    <vue-editor
                      id="recomendation"
                      :editorToolbar="customToolbar"
                      v-model="modalForm.recomendation"
                    ></vue-editor>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="priority_label"
                    label="Prioritas :"
                    label-for="priority"
                  >
                    <b-form-select
                      size="sm"
                      id="priority"
                      v-model="modalForm.priority"
                      :options="priority"
                    ></b-form-select>
                  </b-form-group>
                 
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="repair_taken_label"
                    label="Perbaikan yang dilakukan :"
                    label-for="repair_taken"
                  >
                    <vue-editor
                      size="sm"
                      id="repair_taken"
                      :editorToolbar="customToolbar"
                      v-model="modalForm.repair_taken"
                    ></vue-editor>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="status_label"
                    label="Status :"
                    label-for="status"
                  >
                    <b-form-select
                      size="sm"
                      id="status"
                      v-model="modalForm.status"
                      :options="status"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="future_recomendation_label"
                    label="Rekomendasi masa mendatang :"
                    label-for="future_recomendation"
                  >
                    <vue-editor
                      size="sm"
                      id="future_recomendation"
                      :editorToolbar="customToolbar"
                      v-model="modalForm.future_recomendation"
                    ></vue-editor>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-alert
                class="pl-2"
                variant="danger"
                :fade="true"
                v-model="showDismissibleAlert_modal"
              >
                <h6>Peringatan :</h6>
                <br />
                <ul>
                  <li v-for="(val, index) in errors_modal" :key="index">
                    {{ val }}
                  </li>
                </ul>
              </b-alert>
            </form>
          </b-modal>
          <b-modal
            id="modal-add-item-onstream"
            ref="modal"
            size="xl"
            @show="resetModalOnstream"
            @ok="handleOk"
            :hideHeaderClose="true"
            :no-close-on-backdrop="true"
          >
            <template #modal-title>
              {{ formTitle }}
            </template>
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-row>
                <b-col>
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Date :"
                  >
                    <b-form-input v-model="form.date" type="date" size="sm" disabled></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Nomor Laporan :"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Enter Text..."
                      size="sm"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Tujuan Laporan :"
                  >
                    <treeselect
                      size="sm"
                      v-model="form.issued_to"
                      :clearable="true"
                      :multiple="true"
                      :clearOnSelect="true"
                      :async="true"
                      valueFormat="label"
                      :load-options="loadOptionsIssued"
                      disabled
                    />
                  </b-form-group>

                  <h5><u>Lokasi Onstream</u></h5>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Pabrik :"
                    label-for="pabrik_id"
                  >
                    <b-form-input
                      v-model="form.pabrik"
                      type="text"
                      disabled
                      size="sm"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Plant :"
                    label-for="plant_id"
                  >
                    <b-form-select
                      id="plant_id"
                      v-model="form.plant_id"
                      :options="plant"
                      disabled
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Equipment :"
                  >
                    <vue-suggestion
                      :items="equipment"
                      v-model="ItemEquipment"
                      :setLabel="setLabel"
                      :itemTemplate="itemTemplate"
                      inputClasses="form-control"
                      size="sm"
                      disabled
                    >
                    </vue-suggestion>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="part_label"
                    label="Part :"
                    label-for="part_id"
                  >
                    <vue-suggestion
                      id="part_id"
                      :items="part"
                      v-model="ItemPart"
                      :setLabel="setLabel"
                      :itemTemplate="itemTemplate"
                      @changed="inputChangePart"
                      @selected="itemSelectedPart"
                      inputClasses="form-control"
                      placeholder="Enter Text..."
                    >
                    </vue-suggestion>
                  </b-form-group>

                  <div v-if="inspection_plant">
                    <b-form-group
                      label="Cek Inspection Plan pada tombol di bawah, Apakah item ini untuk Inspektion Plan tersebut?"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="modalForm.inspection_plan"
                        :options="[
                          { text: 'Ya', value: 'Ya' },
                          { text: 'Tidak', value: 'Tidak' },
                        ]"
                        :aria-describedby="ariaDescribedby"
                        name="inspection_plan"
                      ></b-form-radio-group>
                    </b-form-group>

                    <div class="accordion" role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle.accordion-1 variant="info">
                            <span class="svg-icon svg-icon-sm svg-icon-white">
                              <i class="flaticon-eye text-success"></i>
                            </span>
                            Detail Inspection Plan
                          </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                          <b-card-body style="font-size: 0.8rem">
                            <b-row class="mb-2">
                              <b-col sm="6" class="text-sm-right"
                                ><b>Mekanisme Kerusakan :</b></b-col
                              >
                              <b-col>{{ inspection_plant.damage_mechanism }}</b-col>
                            </b-row>

                            <b-row class="mb-2">
                              <b-col sm="6" class="text-sm-right"><b>Metode Inspeksi :</b></b-col>
                              <b-col>{{ inspection_plant.metode_inspeksi }}</b-col>
                            </b-row>

                            <b-row class="mb-2">
                              <b-col sm="6" class="text-sm-right"><b>Rencana Inspeksi :</b></b-col>
                              <b-col>{{ moment(inspection_plant.rencana_inspeksi) }}</b-col>
                            </b-row>

                            <b-row class="mb-2" v-if="inspection_plant.alasan_ditunda">
                              <b-col sm="6" class="text-sm-right"><b>Alasan Ditunda :</b></b-col>
                              <b-col>{{ inspection_plant.alasan_ditunda }}</b-col>
                            </b-row>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>

                  <b-alert v-else show variant="warning">Belum ada Inspection Plant.</b-alert>
                  <br />

                  <h5><u>Inspection Detail</u></h5>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="method_label"
                    label="Metode :"
                    label-for="method"
                  >
                    <treeselect
                      size="sm"
                      id="method"
                      v-model="modalForm.method"
                      :options="method"
                      :clearable="true"
                      :multiple="true"
                      placeholder="Pilih Metode Inspeksi..."
                    />
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="damage_mechanism_label"
                    label="Mekanisme Kerusakan :"
                    label-for="damage_mechanism"
                  >
                    <treeselect
                      size="sm"
                      id="damage_mechanism"
                      v-model="modalForm.damage_mechanism"
                      :clearable="true"
                      :multiple="true"
                      :async="true"
                      valueFormat="label"
                      :load-options="loadOptionsDamageMechanism"
                      @select="onSelect"
                    />
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Co Inspector :"
                  >
                    <treeselect
                      size="sm"
                      v-model="form.co_inspector"
                      :clearable="true"
                      :multiple="true"
                      :async="true"
                      valueFormat="label"
                      :load-options="loadOptionsIssued"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="inspection_result_label"
                    label="Hasil Pemeriksaan :"
                    label-for="inspection_result"
                  >
                    <vue-editor
                      id="inspection_result"
                      :editorToolbar="customToolbar"
                      v-model="modalForm.inspection_result"
                    ></vue-editor>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="critically_label"
                    label="Critically :"
                    label-for="critically"
                  >
                    <b-form-select
                      size="sm"
                      id="critically"
                      v-model="modalForm.critically"
                      :options="critically"
                    ></b-form-select>
                  </b-form-group>
                  
                  
                 
                  <b-form-group label="Link Template :" label-for="link_template">
                    <template v-if="isLinkAvailable">
                      <a :href="url_template" target="_blank">{{ linkText }}</a>
                    </template>
                    <template v-else>
                      <span>{{ linkText }}</span>
                    </template>
                  </b-form-group>

                  <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="link_baru"
                      label="Link Attachment Baru :"
                      label-for="link_baru"
                    >
                      <b-form-input
                        id="link_baru"
                        v-model="modalForm.attachment_link"
                        type="text"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="img_label"
                    label="Image :"
                    label-for="img"
                    v-if="modalForm.image.length < 6"
                  >
                    <b-button class="mb-2" variant="outline-info" size="sm" @click="addImage">
                      <span class="svg-icon svg-icon-sm svg-icon-dark">
                        <i class="flaticon2-plus"></i>
                      </span>
                      Tambah Gambar
                    </b-button>
                  </b-form-group>

                  <div v-for="(img, index) in modalForm.image" v-bind:key="index">
                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      :id="`img_label_${index}`"
                      :label="`Gambar ${index + 1} :`"
                      :label-for="`img_${index}`"
                    >
                      <b-form-file
                        :id="`img_${index}`"
                        accept=".jpg, .png, .jpeg, .pdf"
                        v-model="modalForm.image[index]"
                        placeholder="Choose a photo or drop it here..."
                        drop-placeholder="Drop image here..."
                      ></b-form-file>
                      <b-form-input
                        class="mt-1"
                        v-model="modalForm.image_keterangan[index]"
                        :placeholder="`Keterangan Gambar ${index + 1}...`"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="recomendation_label"
                    label="Rekomendasi :"
                    label-for="recomendation"
                  >
                    <vue-editor
                      id="recomendation"
                      :editorToolbar="customToolbar"
                      v-model="modalForm.recomendation"
                    ></vue-editor>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="priority_label"
                    label="Prioritas :"
                    label-for="priority"
                  >
                    <b-form-select
                      size="sm"
                      id="priority"
                      v-model="modalForm.priority"
                      :options="priority"
                    ></b-form-select>
                  </b-form-group>
                 
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="repair_taken_label"
                    label="Perbaikan yang dilakukan :"
                    label-for="repair_taken"
                  >
                    <vue-editor
                      size="sm"
                      id="repair_taken"
                      :editorToolbar="customToolbar"
                      v-model="modalForm.repair_taken"
                    ></vue-editor>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="status_label"
                    label="Status :"
                    label-for="status"
                  >
                    <b-form-select
                      size="sm"
                      id="status"
                      v-model="modalForm.status"
                      :options="status"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="future_recomendation_label"
                    label="Rekomendasi masa mendatang :"
                    label-for="future_recomendation"
                  >
                    <vue-editor
                      size="sm"
                      id="future_recomendation"
                      :editorToolbar="customToolbar"
                      v-model="modalForm.future_recomendation"
                    ></vue-editor>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-alert
                class="pl-2"
                variant="danger"
                :fade="true"
                v-model="showDismissibleAlert_modal"
              >
                <h6>Peringatan :</h6>
                <br />
                <ul>
                  <li v-for="(val, index) in errors_modal" :key="index">
                    {{ val }}
                  </li>
                </ul>
              </b-alert>
            </form>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ApiService from '@/core/services/api.service'

import { VueEditor } from 'vue2-editor'
import $ from 'jquery'
import * as moment from 'moment'
import 'moment/locale/id'

import Treeselect from '@riophae/vue-treeselect'
import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import itemTemplate from '@/view/pages/item-template.vue'

const simulateAsyncOperation = fn => {
  setTimeout(fn, 2000)
}

export default {
  name: 'addcreatereport',
  data() {
    return {
      // urlInput: 'https://docs.google.com/spreadsheets/d/1iYI3KR3pUremstXEanvFuhTZdEupyrOpj6CzWeRpa8g/edit?gid=844969123#gid=844969123',
      formTitle: 'Form Add Item',
      optes: [{ id: 'xx', label: 'pp' }],
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [
          {
            list: 'ordered',
          },
          {
            list: 'bullet',
          },
          {
            list: 'check',
          },
        ],
        [
          {
            align: '',
          },
          {
            align: 'center',
          },
          {
            align: 'right',
          },
          {
            align: 'justify',
          },
        ],
      ],

      form: {
        date: null,
        report_no: null,
        issued_to: [],
        pabrik_id: null,
        plant_id: null,
        pabrik: '',
        plant: '',
        no_notifikasi: '',
        equipment_id: null,
        co_inspector: [],
        file: null,
        // eksekutor: null,
        attachment_link: null,
        no_wo: null,
      },
      // eksekutorOptions: [],
      // linkMessage: "Pilih Equipment terlebih dahulu pada menu utama",
      url_template: null,
      equipment: [],
      pabrik: [],
      plant: [],
      issued: [],
      part: [],
      inspection_plant: null,
      modalForm: {
        part: null,
        id_inspection_plan: null,
        inspection_plan: null,
        critically: null,
        onstream: null,
        status: 'Belum dilakukan',
        inspection_result: '',
        recomendation: '',
        repair_taken: '',
        future_recomendation: '',
        method: [],
        damage_mechanism: [],
        image: [],
        image_keterangan: [],
        priority: null,
        link_template:'',
        attachment_link:'',
      },
      critically: [
        { value: null, text: '-- Pilih Critically --' },
        { value: '1', text: 'Good' },
        { value: '2', text: 'Considerable' },
        { value: '3', text: 'Warning' },
        { value: '4', text: 'Bad' },
        { value: '5', text: 'Breakdown' },
      ],
      onstream: [
        { value: 'Onstream', text: 'Onstream' },
        { value: 'Shutdown', text: 'Shutdown' },
      ],
      priority: [
        { value: null, text: '-- Pilih Priority --' },
        { value: 'Low', text: 'Low' },
        { value: 'Medium', text: 'Medium' },
        { value: 'High', text: 'High' },
        { value: 'Turn Arround', text: 'Turn Arround' },
      ],
      status: [
        { value: 'Belum dilakukan', text: 'Belum dilakukan' },
        { value: 'Sudah dilakukan', text: 'Sudah dilakukan' },

        {
          value: 'Dilakukan tidak sesuai rekomendasi',
          text: 'Dilakukan tidak sesuai rekomendasi',
        },
      ],
      method: [
        { id: 'Visual', label: 'Visual' },
        { id: 'Ultrasonic Thickness', label: 'Ultrasonic Thickness' },
        { id: 'Radiography', label: 'Radiography' },
        { id: 'Ultrasonic', label: 'Ultrasonic' },
        { id: 'Liquid Penetrant', label: 'Liquid Penetrant' },
        { id: 'Magnetic Particle', label: 'Magnetic Particle' },
        { id: 'Eddy Current', label: 'Eddy Current' },
        { id: 'Leak Test', label: 'Leak Test' },
        {
          id: 'Hydrostatic Pressure Test',
          label: 'Hydrostatic Pressure Test',
        },
        { id: 'Thermography', label: 'Thermography' },
        { id: 'Hardness Test', label: 'Hardness Test' },
      ],
      damage_mechanism: [],
      items: [],
      fields: [
        { key: 'no', label: 'No', sortable: true },
        { key: 'part', label: 'Part', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      totalRows: 1,
      totalPerPage: 0,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],

      ItemEquipment: null,
      // ItemPlant: null,
      ItemPart: null,
      itemTemplate,

      show: true,
      isBusy: false,
      loading: false,
      errors: [],
      errors_modal: [],
      showDismissibleAlert: false,
      showDismissibleAlert_modal: false,
      modalIndex: -1,
    }
  },
  components: {
    KTCodePreview,
    VueEditor,
    Treeselect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Report', route: '/report/index' },
      { title: 'Create Report' },
    ])

    this.totalRows = this.items.length
  },
  async created() {
    var self = this

    await ApiService.setHeader()

    await ApiService.get('/master/pabrik/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var pabrik = data.data
          for (let i = 0; i < pabrik.length; i++) {
            self.pabrik.push({
              value: pabrik[i].id_pabrik,
              text: pabrik[i].nama_pabrik,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })
      
  },
  computed: {
    linkText() {
      if (this.url_template === null) {
        return "Pilih Equipment terlebih dahulu pada menu utama";
      } else if (this.url_template === "") {
        return "Link Template tidak tersedia pada database";
      } else {
        return "Buka Tautan";
      }
    },
    isLinkAvailable() {
      // Akan mengembalikan true jika ada url_template, false jika tidak
      return !!this.url_template;
    }
  },
  watch: {
    'modalForm.onstream': function (newValue) {
      console.log("Selected option changed to:", newValue); // Debugging log
    }
  },
  methods: {
    moment: function(date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD LTS').format('dddd, LL')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    toEdit(index) {
      this.$bvModal.show('modal-add-item')
      this.formTitle = 'Edit'
      this.modalIndex = index
      var e = {
        status: this.items[index].status,
        critically: this.items[index].critically,
        onstream: this.items[index].onstream,
        part: this.items[index].part,
        id_inspection_plan: this.items[index].id_inspection_plan,
        inspection_plan: this.items[index].inspection_plan,
        inspection_result: this.items[index].inspection_result,
        recomendation: this.items[index].recomendation,
        repair_taken: this.items[index].repair_taken,
        future_recomendation: this.items[index].future_recomendation,
        method: this.items[index].method,
        damage_mechanism: this.items[index].damage_mechanism,
        image: this.items[index].img,
        image_keterangan: this.items[index].image_keterangan,
        priority: this.items[index].priority,
        attachment_link: this.items[index].attachment_link,
      }
      this.modalForm = e
    },
    toDelete(index) {
      this.isBusy = true
      // Hide the modal manually
      this.$nextTick(() => {
        this.items.splice(index, 1)
        this.isBusy = false
      })
    },
    resetModal() {
      this.modalIndex = -1
      this.formTitle = 'Form Add Item'
      this.modalForm = {
        part: null,
        id_inspection_plan: null,
        inspection_plan: null,
        critically: 1,
        onstream: 'Shutdown',
        status: 'Belum dilakukan',
        inspection_result: '-',
        recomendation: '-',
        repair_taken: '-',
        future_recomendation: '-',
        method: [],
        damage_mechanism: [],
        image: [],
        image_keterangan: [],
        priority: 'Low',
        attachment_link:'',
      }
      this.showDismissibleAlert_modal = false
    },
    resetModalOnstream() {
      this.modalIndex = -1
      this.formTitle = 'Form Add Item Onstream'
      this.modalForm = {
        part: null,
        id_inspection_plan: null,
        inspection_plan: null,
        critically: 1,
        onstream: 'Onstream',
        status: 'Belum dilakukan',
        inspection_result: '-',
        recomendation: '-',
        repair_taken: '-',
        future_recomendation: '-',
        method: [],
        damage_mechanism: [],
        image: [],
        image_keterangan: [],
        priority: 'Low',
        attachment_link:'',
      }
      this.showDismissibleAlert_modal = false
    },
    addImage() {
      this.modalForm.image.push(null)
      this.modalForm.image_keterangan.push(null)
    },
    async choosePabrik() {
      var self = this
      await ApiService.get(
        '/master/plant/all?pabrik_id=' + self.form.pabrik_id + '&sortBy=nama_plant&sortDesc=ASC'
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            var plant = data.data.data
            self.plant.splice(0, self.plant.length)
            self.plant.push({
              value: null,
              text: '-- Pilih Plant --',
            })
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                value: plant[i].id_plant,
                text: plant[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      // await ApiService.get('/master/eksekutor/all?pabrik_id=' + self.form.pabrik_id)
      //   .then(({ data }) => {
      //     if (data.status == 'ok') {
      //       var eksekutor = data.data
      //       self.eksekutorOptions.splice(0, self.eksekutorOptions.length)
      //       self.eksekutorOptions.push({
      //         value: null,
      //         text: '-- Pilih Eksekutor --',
      //       })
      //       for (let i = 0; i < eksekutor.length; i++) {
      //         self.eksekutorOptions.push({
      //           value: eksekutor[i].nama_eksekutor,
      //           text: eksekutor[i].nama_eksekutor,
      //         })
      //       }
      //     }
      //   })
      //   .catch(response => {
      //     console.log(response)
      //   })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.isBusy = true
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Push the name to submitted names
      var part = null

      if (typeof this.modalForm.part === 'object' && this.modalForm.part !== null) {
        part = this.modalForm.part.name
      } else {
        part = this.modalForm.part
      }

      if (part != null) {
        if (this.modalIndex == -1) {
          this.items.push({
            part: part,
            id_inspection_plan: this.modalForm.id_inspection_plan,
            inspection_plan: this.modalForm.inspection_plan,
            critically: this.modalForm.critically,
            onstream: this.modalForm.onstream,
            status: this.modalForm.status,
            inspection_result: this.modalForm.inspection_result,
            recomendation: this.modalForm.recomendation,
            repair_taken: this.modalForm.repair_taken,
            future_recomendation: this.modalForm.future_recomendation,
            method: this.modalForm.method,
            damage_mechanism: this.modalForm.damage_mechanism,
            img: this.modalForm.image,
            image_keterangan: this.modalForm.image_keterangan,
            priority: this.modalForm.priority,
            attachment_link:this.modalForm.attachment_link,
          })
        } else {
          var d = {
            part: part,
            id_inspection_plan: this.modalForm.id_inspection_plan,
            inspection_plan: this.modalForm.inspection_plan,
            critically: this.modalForm.critically,
            onstream: this.modalForm.onstream,
            status: this.modalForm.status,
            inspection_result: this.modalForm.inspection_result,
            recomendation: this.modalForm.recomendation,
            repair_taken: this.modalForm.repair_taken,
            future_recomendation: this.modalForm.future_recomendation,
            method: this.modalForm.method,
            damage_mechanism: this.modalForm.damage_mechanism,
            img: this.modalForm.image,
            image_keterangan: this.modalForm.image_keterangan,
            priority: this.modalForm.priority,
            attachment_link:this.modalForm.attachment_link,
          }
          this.items[this.modalIndex] = d
        }

        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-add-item')
          this.isBusy = false
        })
      }

      this.errors_modal.splice(0, this.errors_modal.length)

      if (!part) {
        this.errors_modal.push('Part Harus Terisi')
      }

      this.showDismissibleAlert_modal = true
    },
    onSubmit() {
      // event.preventDefault()
      var self = this
      self.loading = true
      const date = self.form.date
      const report_no = self.form.report_no
      const no_notifikasi = self.form.no_notifikasi
      const issued_to = self.form.issued_to
      const pabrik_id = self.form.pabrik_id
      const plant_id = self.form.plant_id
      const equipment_id = self.form.equipment_id
      // const eksekutor = self.form.eksekutor
      const no_wo = self.form.no_wo
      const co_inspector = self.form.co_inspector
      const file = self.form.file

      // console.log("date : " + date)
      // console.log("report_no : " + report_no)
      // console.log("no_notifikasi : " + no_notifikasi)
      // console.log("issued_to : " + JSON.stringify(issued_to))
      // console.log("pabrik_id : " + pabrik_id)
      // console.log("plant_id : " + plant_id)
      // console.log("equipment_id : " + equipment_id)
      // console.log("eksekutor : " + eksekutor)
      // console.log("no_wo : " + no_wo)
      // console.log("co_inspector : " + JSON.stringify(co_inspector))
      // console.log("file : " + file)

      const dataku = new FormData()

      var items = self.items
      for (let i = 0; i < items.length; i++) {
        dataku.append('items[' + i + '][part]', items[i].part)
        dataku.append('items[' + i + '][inspection_plan]', items[i].inspection_plan)
        if (items[i].inspection_plan === 'Ya') {
          dataku.append('items[' + i + '][id_inspection_plan]', items[i].id_inspection_plan)
        }
        dataku.append('items[' + i + '][critically]', items[i].critically)
        dataku.append('items[' + i + '][onstream]', items[i].onstream)
        dataku.append('items[' + i + '][status]', items[i].status)
        dataku.append('items[' + i + '][inspection_result]', items[i].inspection_result)
        dataku.append('items[' + i + '][recomendation]', items[i].recomendation)
        //untuk menyisipkan link attachment baru
        dataku.append('items[' + i + '][attachment_link]', items[i].attachment_link)
        dataku.append('items[' + i + '][repair_taken]', items[i].repair_taken)
        dataku.append('items[' + i + '][future_recomendation]', items[i].future_recomendation)
        dataku.append(
          'items[' + i + '][damage_mechanism]',
          items[i].damage_mechanism.map(x => x.id).toString()
        )

        var method = items[i].method
        for (let j = 0; j < method.length; j++) {
          dataku.append('items[' + i + '][method][' + j + ']', method[j])
        }

        dataku.append('items[' + i + '][priority]', items[i].priority)
        var img = items[i].img
        var img_keterangan = items[i].image_keterangan
        for (let j = 0; j < img.length; j++) {
          dataku.append('items[' + i + '][img][' + j + ']', img[j])
          dataku.append('items[' + i + '][img_keterangan][' + j + ']', img_keterangan[j])
        }
      }

      for (let i = 0; i < issued_to.length; i++) {
        dataku.append('issued_to[' + i + ']', JSON.stringify(issued_to[i]))
      }

      if (co_inspector) {
        for (let i = 0; i < co_inspector.length; i++) {
          dataku.append('co_inspector[' + i + ']', JSON.stringify(co_inspector[i]))
        }
      }

      if (date) dataku.append('date', date)
      dataku.append('no_notifikasi', no_notifikasi)
      if (report_no) dataku.append('no_report', report_no)
      if (pabrik_id) dataku.append('pabrik_id', pabrik_id)
      if (plant_id) dataku.append('plant_id', plant_id)
      if (equipment_id) dataku.append('equipment_id', equipment_id)
      // if (eksekutor) dataku.append('eksekutor', eksekutor)
      if (no_wo) dataku.append('no_wo', no_wo)
      // if (date) dataku.append("inspector", inspector);

      if (file) {
        dataku.append('file', file)
      }
      dataku.append('report_type', 'report')
      // console.log(self.items)
      var urlApi = ApiService.urlApi()
      $.ajax({
        url: urlApi + '/report/create/store',
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/report/index')
          } else if (response.status == 'validation_error') {
            self.errors = response.errors
            self.showDismissibleAlert = true
            self.loading = false
            self.$refs['error-modal'].show()
          }
        },
      })
    },
    setLabel(item) {
      return item.name
    },
    inputChangeEquipment(text) {
    var self = this;
    var url = '/master/equipment/showequipment/' + text;
    
    if (this.form.pabrik_id && text) {
      url += '/' + this.form.pabrik_id;
    }

    if (this.form.plant_id && text) {
      url += '/' + this.form.plant_id;
    }

    ApiService.get(url)
      .then(({ data }) => {
        console.log(data);
        if (data.status === 'ok') {
          var equipment = data.data;
          var url_template = data.link_template;

          // Clear and populate the equipment array with new data
          self.equipment.splice(0, self.equipment.length);

          for (let i = 0; i < equipment.length; i++) {
            self.equipment.push({
              id: equipment[i].id_equipment,
              name: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
              plant_id: equipment[i].plant_id,
              pabrik_id: equipment[i].pabrik_id,
              pabrik: equipment[i].nama_pabrik,
              plant: equipment[i].nama_plant,
              link_template: url_template|| ""
            });
          }

          // Reset url_template and ItemEquipment for new selection
          // this.url_template = "";
          // this.ItemEquipment = null;
        }
      })
      .catch(response => {
        console.log(response);
      });
    },
    inputChangePlant(text) {
      // your search method
      var self = this
      var url = '/master/plant/showplant/' + text
      if (this.form.pabrik_id && text) {
        url += '/' + this.form.pabrik_id
      }

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var plant = data.data

            self.plant.splice(0, self.plant.length)
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                id: plant[i].id_plant,
                name: plant[i].nama_plant,
                pabrik: plant[i].nama_pabrik,
                pabrik_id: plant[i].pabrik_id,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    onSelect() {
      this.$refs.report_no.focus()
    },
    
    loadOptionsIssued({ action, searchQuery, callback }) {
    var self = this;

    if (action === ASYNC_SEARCH && searchQuery.length >= 3) {
        simulateAsyncOperation(() => {
            ApiService.setHeader();

            ApiService.get('/report/create/showissued/' + searchQuery)
                .then(({ data }) => {
                    if (data.status === 'ok' && Array.isArray(data.data)) {
                        var issued = data.data;
                        
                        self.issued.splice(0, self.issued.length); // Clear existing data

                        for (let i = 0; i < issued.length; i++) {
                            self.issued.push({
                                id: issued[i].no_badge,  // Fix the indexing here
                                label: issued[i].nama,   // Fix the indexing here
                            });
                        }

                        // Call the callback with the updated issued data
                        callback(null, self.issued);
                    } else {
                        // Handle the case when data.status is not 'ok' or data.data is not an array
                        console.error("Unexpected data format", data);
                        callback("Unexpected data format", []);
                    }
                })
                .catch(error => {
                    console.log(error);
                    callback(error, []);
                });
        });
    } else {
        // Handle the case when action is not ASYNC_SEARCH or searchQuery is too short
        callback("Terjadi Kesalahan, kemungkinan pencarian terlalu pendek", []);
    }
  },

    loadOptionsDamageMechanism({ action, searchQuery, callback }) {
      var self = this

      if (action === ASYNC_SEARCH && searchQuery.length >= 3) {
        simulateAsyncOperation(() => {
          ApiService.setHeader()

          ApiService.get('/master/inspectionplant/showdamagemechanism/' + searchQuery)
            .then(({ data }) => {
              if (data.status == 'ok') {
                var damage_mechanism = data.data

                self.damage_mechanism.splice(0, self.damage_mechanism.length)
                for (let i = 0; i < damage_mechanism.length; i++) {
                  self.damage_mechanism.push({
                    id: damage_mechanism[i].id_damage_mechanism,
                    label: damage_mechanism[i].nama_damage_mechanism,
                  })
                }
              }
            })
            .catch(response => {
              console.log(response)
            })

          callback(null, self.damage_mechanism)
        })
      }
    },
    itemSelectedPart(item) {
      this.modalForm.part = item
      this.checkinspectionplant()
    },
    inputChangePart(text) {
      // your search method
      var self = this
      ApiService.get('/master/part?page=1&nama_part=' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var part = data.data.data
            self.part.splice(0, self.part.length)

            for (let i = 0; i < part.length; i++) {
              self.part.push({
                id: part[i].id_part,
                name: part[i].nama_part,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      this.modalForm.part = text
    },
    chooseEquipment: function(value) {
      this.form.equipment_id = value.id;
      this.form.pabrik_id = value.pabrik_id;
      this.choosePabrik();
      this.form.plant_id = value.plant_id;
      this.form.pabrik = value.pabrik;
      this.form.plant = value.plant;
       
      // Set the URL template from the selected equipment
      this.url_template = value.link_template || "";
      // console.log("Selected URL Template:", this.url_template); // Debug
      this.$forceUpdate(); // Force update UI
      // console.log(typeof this.url_template); // Harusnya "string"

    },
    checkinspectionplant() {
      var self = this
      if (self.form.date && self.form.equipment_id && self.modalForm.part.id) {
        var cekInput = self.form.date +' - '+self.form.equipment_id+' - '+ self.modalForm.part.id
        //format yang dicek 2024-07-01 - 171996976525065 - 1644379410541299
        ApiService.get(
          '/inspectionplan/checkforreport/' +
            self.form.date +
            '/' +
            self.form.equipment_id +
            '/' +
            self.modalForm.part.id
        )
          .then(({ data }) => {
            if (data.status == 'ok') {
              var inspection_plant = data.data
              self.inspection_plant = inspection_plant
              self.modalForm.id_inspection_plan = inspection_plant.id_inspection_plan
            }
          })
          .catch(response => {
            //terjadi kesalahan dalam mengambil id_inspection_plan karena data kosong
            //sehingga nilai yang dikembalikan adalah null
            //sudah aman kembali ke data input report
            console.log(response)
            // console.log(cekInput)
          })
      }
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
